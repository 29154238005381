@import '../../styles/colours'

.Map
  width: 100%
  height: 60vh

.Area
  font-family: 'Handlee', cursive
  opacity: 1
  position: relative

  $point-diameter: 30px

  .Area--dot
    font-size: 1.3em
    opacity: 1
    cursor: pointer
    z-index: 1
    position: relative

  .Area--centre
    width: $point-diameter
    height: $point-diameter
    background: $primary-colour
    border-radius: $point-diameter
    top: -$point-diameter / 2
    left: -$point-diameter / 2

    &:hover
      background-color: $primary-colour-dark
      opacity: 1
      z-index: 10

  .DaySelector
    $selector-diameter: $point-diameter * 0.7
    width: $selector-diameter
    height: $selector-diameter
    top: -$selector-diameter / 2
    left: -$selector-diameter / 2
    background: $secondary-colour
    color: $secondary-colour-text
    border-radius: $selector-diameter
    cursor: pointer
    position: relative
    margin-bottom: 10px
    text-align: center
    line-height: $selector-diameter

    &:hover
      background: $secondary-colour-dark

      a
        color: $secondary-colour-dark-text

    a
      text-decoration: none
      color: $secondary-colour-text

    &.selected
      background-color: $secondary-colour-dark

      a
        color: $secondary-colour-dark-text

  .Area--title
    font-size: 1.5em
    background: white
    opacity: 1
    position: absolute
    top: -$point-diameter / 2
    left: $point-diameter / 2 + 5px
    padding: 5px
    border-radius: 5px
    z-index: 1
    border: 2px solid $secondary-colour-dark

  &.selected
    .Area--dot, .Area--title
      z-index: 10

    .Area--centre
      background: $secondary-colour-dark
      opacity: 1
