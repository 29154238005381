$red: #F44336
$red-text: white
$red-light: #E57373
$red-light-text: black
$red-dark: #C62828
$red-dark-text: white
$red-faded: #FFCDD2
$red-faded-text: grey

$orange: #FF9800
$orange-text: white
$orange-light: #FFB74D
$orange-light-text: black
$orange-dark: #EF6C00
$orange-dark-text: white
$orange-faded: #FFE0B2
$orange-faded-text: grey

$green: #4CAF50
$green-text: black
$green-light: #81C784
$green-light-text: black
$green-dark: #2E7D32
$green-dark-text: white
$green-faded: #C8E6C9
$green-faded-text: grey

$cyan: #00BCD4
$cyan-text: black
$cyan-light: #4DD0E1
$cyan-light-text: black
$cyan-dark: #00838F
$cyan-dark-text: white
$cyan-faded: #B2EBF2
$cyan-faded-text: grey

$blue: #2196F3
$blue-text: black
$blue-light: #64B5F6
$blue-light-text: black
$blue-dark: #1565C0
$blue-dark-text: white
$blue-faded: #BBDEFB
$blue-faded-text: grey

$blue-grey: #607D8B
$blue-grey-text: white
$blue-grey-light: #90A4AE
$blue-grey-light-text: black
$blue-grey-dark: #37474F
$blue-grey-dark-text: white
$blue-grey-faded: #CFD8DC
$blue-grey-faded-text: grey

$indigo: #3F51B5
$indigo-text: white
$indigo-light: #7986CB
$indigo-light-text: black
$indigo-dark: #283593
$indigo-dark-text: white
$indigo-faded: #C5CAE9
$indigo-faded-text: grey

$purple: #9C27B0
$purple-text: white
$purple-light: #BA68C8
$purple-light-text: black
$purple-dark: #6A1B9A
$purple-dark-text: white
$purple-faded: #E1BEE7
$purple-faded-text: grey

$primary-colour: $cyan
$primary-colour-text: $cyan-text
$primary-colour-light: $cyan-light
$primary-colour-light-text: $cyan-light-text
$primary-colour-dark: $cyan-dark
$primary-colour-dark-text: $cyan-dark-text
$primary-colour-faded: $cyan-faded
$primary-colour-faded-text: $cyan-faded-text

$secondary-colour: $blue-grey
$secondary-colour-text: $blue-grey-text
$secondary-colour-light: $blue-grey-light
$secondary-colour-light-text: $blue-grey-light-text
$secondary-colour-dark: $blue-grey-dark
$secondary-colour-dark-text: $blue-grey-dark-text
$secondary-colour-faded: $blue-grey-faded
$secondary-colour-faded-text: $blue-grey-faded-text
