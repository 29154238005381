@import '../../styles/colours'

.costChart
  position: relative
  width: 100%
  height: 100%

  .costChart--bars
    position: relative
    width: 100%
    height: 100%
    z-index: 2

    .day
      width: 100%
      background-color: transparent

      &.focused
        .cost
          &.food
            background-color: $red

          &.accommodation
            background-color: $orange

          &.activities
            background-color: $green

          &.shortTravel
            background-color: $indigo

          &.longTravel
            background-color: $blue

          &.other
            background-color: $purple

  .cost
    height: 1.5em
    display: inline-block
    font-size: 0.8em

    &.food
      background-color: $red-light
      color: $red-light-text

    &.accommodation
      background-color: $orange-light
      color: $orange-light-text

    &.activities
      background-color: $green-light
      color: $green-light-text

    &.shortTravel
      background-color: $indigo-light
      color: $indigo-light-text

    &.longTravel
      background-color: $blue-light
      color: $blue-light-text

    &.other
      background-color: $purple-light
      color: $purple-light-text

    &.total
      display: none

  .axis
    position: absolute
    width: 100%
    height: 100%
    z-index: 1

    .axis--line
      position: relative
      border-left: 1px dashed #aaa
      height: 100%
      display: inline-block

  .axis--labels
    position: sticky
    top: 0
    width: 100%
    height: 1.5em
    padding-top: 0.5em
    background-color: white
    z-index: 3

    .axis--label
      position: absolute
      top: 0.5em
      font-size: 0.8em
      transform: translateX(-50%)

  &.costBreakdown
    position: fixed
    top: 60px
    right: 5%
    z-index: 20
    width: 75%
    height: 200px
    background-color: white
    border: 1px solid #aaa
    box-shadow: 5px 5px 10px #aaa

    .day
      padding: 5px

      .cost
        display: block
        margin-bottom: 5px

      .categoryBreakdown
        width: 100%

  .categoryColumn
    text-align: right
    padding-right: 5px

  .costColumn
    width: 100%
    font-size: 1em
    font-weight: bold

  .costRow.total
    display: none

