@import './colours'

html
  font-size: 16px
  overflow-x: hidden

body
  font-family: 'Handlee', cursive
  font-size: 1.1em

.Rosie
  font-family: 'Tillana', serif
  font-style: italic
  font-size: 1em

img
  max-width: 100%
  height: auto

a
  color: $primary-colour

  &:hover
    color: $primary-colour-dark
